import exclusive from '../assets/exclusive.webp'
import instagram from '../assets/instagram.webp'
import pricing from '../assets/pricing.webp'
import paypal from '../assets/paypal.webp'
import telegram from '../assets/telegram.webp'
import soon from '../assets/soon.webp'

const items = [{
    "title": "Exclusive content",
    "subtitle": "Zobacz moją ukrytą zawartość!",
    "image": exclusive,
    "link": "https://t.me/animozja" //exclusive
},
{
    "title": "Instagram",
    "subtitle": "Mój profil na instagramie",
    "image": instagram,
    "link": "https://instagram.com/weronika_animozja" //instagram
},
{
    "title": "Telegram",
    "subtitle": "Tutaj możesz się ze mną skontaktować",
    "image": telegram,
    "link": "https://t.me/weronika_animozja" //telegram
},
{
    "title": "PayPal",
    "subtitle": "Tutaj znajdziesz moje konto na PayPalu",
    "image": paypal,
    "link": "https://paypal.me/animozja" //paypal
},
{
    "title": "Cennik",
    "subtitle": "Sprawdź mój cennik",
    "image": pricing,
    "link": "https://t.me/animozja_cennik" //cennik 
},
{
    "title": "Wkrótce",
    "subtitle": "Czymś was zaskoczę ;)",
    "image": soon,
    "link": "animozja.pl" //coś na później
}]

export default items
